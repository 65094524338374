











































































import { Vue, Component } from "vue-property-decorator";
import ContactBox from "@/components/ContactBox.vue";
import ContainersList from "@/components/ContainersList.vue";
import Locations, { Location } from "@/store/locations";
import * as R from "ramda";

type FetchedContainers = {
  id: string;
  name: string;
  size: string;
  image_url: string;
};

@Component({
  components: {
    ContactBox,
    ContainersList
  }
})
export default class extends Vue {
  model = {
    slug: "",
    name: "",
    containers: [] as any,
    city: "",
    streetAddress: "",
    zipCode: "",
    state: "",
    type: "",
    contacts: [] as any,
    lat: 0,
    lng: 0,
    isReturnStation: false,
    isVendor: false
  };
  error = "";
  isLoading = false;
  fetchedContainers: FetchedContainers[] = [];
  loadingQR = false;

  get slug() {
    return this.$route.params.slug;
  }

  get type() {
    return this.$route.params.type;
  }

  get locationType() {
    if (this.model.isReturnStation && this.model.isVendor) {
      return 'Vendor and Return Station';
    } else if (this.model.isReturnStation) {
      return 'Return Station';
    } else if (this.model.isVendor) {
      return 'Vendor';
    } else {
      return '';
    }
  }

  get markerLocation() {
    return { lat: this.model.lat, lng: this.model.lng };
  }
  
  get mapCenter() {
    if (this.isMarkerSet) {
      return this.markerLocation;
    }

    return { lat: 40, lng:-100 };
  }

  get isMarkerSet() {
    if (this.model && this.model.lat && this.model.lng) {
      return true;
    }

    return false;
  }

  get mapZoom() {
    return this.isMarkerSet ? 10 : 3;
  }

  edit() {
      this.$router.push({ name: 'locations-edit', params: { type: this.type, slug: this.slug }});
  }

  async mounted() {
    this.isLoading = true;
    try {
      const response = await Locations.fetchOne({ slug: this.slug });
      const containersRespone = await Locations.fetchContainers();
      this.fetchedContainers = containersRespone.data as FetchedContainers[];
      const fetchedLocation = response.data as Location;
      (this.model as any) = R.clone(fetchedLocation);
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async getQRCode(type?: string) {
    this.loadingQR = true;
    try {
      await Locations.getQRCode({ slug: this.model.slug, type });
    } catch (err) {
      alert(err.message);
    }
    this.loadingQR = false;
  }
}
